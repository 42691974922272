import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import iconReport from "../images/icon-report.svg"
import iconReview from "../images/icon-review.svg"
import iconStore from "../images/icon-store.svg"
import iconTrack from "../images/icon-track.svg"
import icon from "../images/icon.svg"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Access Your Contracts – Anytime, Anywhere" />
    <section id="services" className="px-2 py-8 xl:py-12">
      <div className="max-w-3xl mx-auto">
        <h2 className="mb-8 text-3xl italic font-bold text-center uppercase text-blue">
          Our Services
        </h2>
        <div class="grid sm:grid-cols-2 gap-8">
          <div className="text-center">
            <img
              alt=""
              className="w-20 h-auto mx-auto mb-4 lg:w-24"
              src={iconStore}
            />
            <div>
              <span className="text-lg font-bold">MANAGE and STORE</span>{" "}
              electronic versions of any document for easy access and retrieval.
            </div>
          </div>
          <div className="text-center">
            <img
              alt=""
              className="w-20 h-auto mx-auto mb-4 lg:w-24"
              src={iconTrack}
            />
            <div>
              <span className="text-lg font-bold">TRACK</span> the status of a
              contract from the time it hits your desk for review until it is
              signed or terminated.
            </div>
          </div>
          <div className="text-center">
            <img
              alt=""
              className="w-20 h-auto mx-auto mb-4 lg:w-24"
              src={iconReview}
            />
            <div>
              <span className="text-lg font-bold">REVIEW and COMMENT</span> on
              proposed agreements, amendments or addendums by qualified
              professionals.
            </div>
          </div>
          <div className="text-center">
            <img
              alt=""
              className="w-20 h-auto mx-auto mb-4 lg:w-24"
              src={iconReport}
            />
            <div>
              <span className="text-lg font-bold">PREPARE</span> reports to help
              you manage your contracts.
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="advantages"
      className="px-2 py-8 text-white bg-gray-500 xl:py-12"
    >
      <div className="max-w-screen-md mx-auto lg:max-w-screen-lg">
        <div class="flex gap-6 justify-between items-center flex-wrap">
          <div class="w-full lg:w-1/4">
            <img className="block w-1/3 mx-auto lg:w-full" alt="" src={icon} />
          </div>
          <div className="w-full lg:w-2/3">
            <h2 className="mb-8 text-3xl italic font-bold uppercase text-beige">
              Advantages
            </h2>
            <ul class="space-y-2">
              <li>
                <b>A robust Search Function</b> allows the subscriber to search
                all files quickly and easily.
              </li>
              <li>
                <b>A Contract Summary</b> screen displays key information about
                a contract, so key information can be retrieved quickly and
                easily.
              </li>
              <li>
                <b>Access to contracts</b> by multiple subscribers
                simultaneously.
              </li>
              <li>
                <b>Relevant documents</b> relating to a single vendor or
                customer are located in one file (i.e. agreement, exhibits,
                amendments, and termination notice).
              </li>
              <li>
                <b>Qualified professionals</b> enter key contract information
                and download documents into LegalOp’s proprietary program to
                ensure accuracy.
              </li>
              <li>
                <b>Customized reports</b>, as requested, may be generated by
                LegalOp.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section id="the-business" className="px-2 py-8 bg-beige xl:py-12">
      <div className="max-w-screen-md mx-auto">
        <h2 className="mb-8 text-3xl italic font-bold uppercase">
          The Business
        </h2>
        <p class="mb-4">
          <StaticImage
            src="../images/cheryl-headshot.png"
            alt="Cheryl Wine"
            placeholder="blurred"
            layout="fixed"
            width={100}
            className="float-left mb-2 mr-6"
          />
          Headquartered in Spartanburg, South Carolina, LegalOp, LLC was founded
          by M. Cheryl Wine, Esquire who has over 30 years of experience in
          establishing and/or managing legal departments, managing the contract
          process, reviewing and drafting contracts and templates, as well as
          handling other responsibilities in textile manufacturing, consumer
          financial services, pharmaceutical drug distribution and technology
          services. Cheryl integrates her corporate experience, managerial
          expertise, and proprietary processes to support existing legal or
          contract departments.
        </p>
        <p>
          Cheryl’s former employers include Michelin, Pluma, Inc., Advance
          America, TitleMax, SunTrust Mortgage, Smith Drug Company and QS/1 Data
          Systems, both divisions of J M Smith Corporation. Cheryl earned her
          B.A. degree in Political Science and French at Clemson University and
          subsequently earned her JD degree from the University of South
          Carolina.
        </p>
      </div>
    </section>
    <section id="contact" className="px-2 py-8 xl:py-12">
      <div className="max-w-screen-md mx-auto">
        <h2 className="mb-8 text-3xl italic font-bold uppercase">Contact</h2>
        <form
          method="POST"
          name="LegalOp Contact Form"
          data-netlify="true"
          className="p-4 mt-4 mb-8 lg:p-6 bg-beige-200"
        >
          <input type="hidden" name="form-name" value="LegalOp Contact Form" />
          <div className="grid-cols-2 gap-4 md:grid">
            <div>
              <label className="block text-gray-600" htmlFor="user-name">
                Your Name
              </label>
              <input
                className="w-full mt-2"
                name="user-name"
                type="text"
                required
              />
            </div>
            <div>
              <label className="block text-gray-600" htmlFor="user-email">
                Your Email
              </label>
              <input
                className="w-full mt-2"
                name="user-email"
                type="email"
                required
              />
            </div>
          </div>
          <div class="mt-4">
            <label className="block text-gray-600" htmlFor="user-message">
              Your Message
            </label>
            <textarea
              className="w-full mt-2"
              name="user-message"
              rows="5"
              maxlength="300"
              required
            />
          </div>
          <button
            className="px-6 py-2 mt-4 text-white bg-purple hover:bg-purple-700"
            type="submit"
          >
            Submit
          </button>
        </form>
        <p className="mb-4">
          For more information about the services provided by LegalOp, please
          use the form above or contact:
        </p>

        <p>
          <b>M. Cheryl Wine, Esquire</b>
          <br />
          President
          <br />
          LegalOp, LLC
          <br />
          <a
            className="underline text-blue hover:text-blue-800"
            href="mailto:cwine@legalop.net"
          >
            cwine@legalop.net
          </a>
          <br />
        </p>
      </div>
    </section>
  </Layout>
)

export default IndexPage
